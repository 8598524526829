import React from "react"
// import { Link } from "gatsby"
import {navigate} from 'gatsby'
import Spinner from 'react-bootstrap/Spinner'

import Layout from "../components/layout"







class Contact extends React.Component {
  constructor(props) {
    super(props)

    this.state ={
      name: '',
      nameerror:'',
      email: '',
      emailerror:'',
      message: '',
      messageerror:'',
      loading:false,
      netresponse: ''
    }
  }

  handleChange = (e) => {
    console.log(e.target.name)
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

    var {name,email,message} = this.state
    console.log(name, email, message)
    if(!name) {
      this.setState({
        nameerror: this.props.pageContext.langcode === 'EL'? 'KENO ONOMA' : 'NOT NULL'
      })
    }
    else {
      this.setState({
        nameerror: ''
      })
    }

    if(!email.match(pattern)) {
      this.setState({
        emailerror: this.props.pageContext.langcode === 'EL'? 'ΛΑΘΟΣ EMAIL' : 'INVALID EMAIL'
      })
    }
    else {
      this.setState({emailerror:''})
    }
    if(!message) {
      this.setState({
        messageerror: this.props.pageContext.langcode === 'EL'? 'KENO ΜΗΝΥΜΑ' : 'NOT NULL'
      })
    }
    else {
      this.setState({messageerror:''})
    }

    if(name && message && email.match(pattern) ) {
      console.log('PASS')
      this.setState({loading:true, name:'', message:'', email:''})
      var data = {

                "webform_id":"contact",



                                "webform_data" : {

                                                "name" : this.state.name,

                                                "email" : this.state.email,



                                                "message" : this.state.message

                                }



  }
  var url = `${process.env.BASE_URL}/graphql/wd_webform/submit`
  console.log(url)
  fetch(url, {
   method:'post',
   mode: 'cors',
   // headers: {
   //      'Content-Type': 'application/json',
   //      // 'Content-Type': 'application/x-www-form-urlencoded',
   //  },
   body:JSON.stringify(data)
  })
  .then(function(response) {


  return response.json()


  })
  .then(res => {
   console.log('message sending in fetch', res)
   if(res.data.submission_id) {
     this.setState({loading:false, netresponse:'Message sent'})
   }
   else {
      this.setState({loading:false, netresponse:'Network Error'})
   }

   setTimeout(() => {
     navigate('/')
   }, 1500)
  })
  .catch((error) => {
      this.setState({loading:false, netresponse:'Network Error'})
     console.log("in error", error)

  })
    }



  }

  render() {



    return  (
      <Layout fixed={true} searcharray={this.props.pageContext.searcharray} path={this.props.path} menu={this.props.pageContext.menu}>


          <section className="blogsection" >
            <h2 className="text-center">Contact</h2>

            <div className="section-full p-tb80 inner-page-padding mt-5">

                    <div className="container">


                        <div className="section-content text-center">



                                  <form style={{maxWidth:"600px"}} className="contact-form cons-contact-form mx-auto" >
                                        <div className="contact-one m-b30">


                                        <div className="section-head">
                                            <div className="mt-separator-outer separator-left">
                                                <div className="mt-separator">

                                                </div>
                                            </div>
                                        </div>


                                            <div className="form-group">
                                                <input value={this.state.name} onChange={this.handleChange} name="name" type="text" required="" className="form-control" placeholder={this.props.pageContext.langcode === 'EN'? 'Name' : 'Ονομα'}/>
                                                <div style={{color:'red'}}>{this.state.nameerror}</div>
                                            </div>

                                            <div className="form-group">
                                                <input  value={this.state.email} onChange={this.handleChange} name="email" type="text" className="form-control" required="" placeholder={this.props.pageContext.langcode === 'EN'? 'Email' : 'Ηλεκτρονικη διευθυνση'}/>
                                                <div style={{color:'red'}}>{this.state.emailerror}</div>
                                            </div>

                                            <div className="form-group">
                                                <textarea value={this.state.message} onChange={this.handleChange} name="message" rows="4" className="form-control " required="" placeholder={this.props.pageContext.langcode === 'EN'? 'Message' : 'Μηνυμα'}></textarea>
                                                <div style={{color:'red'}}>{this.state.messageerror}</div>
                                                {this.state.loading ? <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                                </Spinner> : null}



                                     <h2>{this.state.netresponse}</h2>
                                            </div>

                                          <div className="text-right">
                                                <button style={{backgroundColor:"black", color:"white"}}  onClick={this.handleSubmit} name="submit" type="submit" value="Submit" className="site-button btn btn-effect">
                                                 {this.props.pageContext.langcode === 'EN'? 'Submit' : 'Αποστολη'}
                                                </button>
                                            </div>

                                        </div>
                                    </form>



                        </div>


                    </div>
               </div>






          </section>






      </Layout>

    )
  }
}



export default Contact
